<template>
    <div>
        <Logged />
    </div>
</template>

<script>
// @ is an alias to /src
import Logged from '@/components/users/Logged.vue'

export default {
    name: 'Logged-User',
    components: {
        Logged
    }
}
</script>
