<template>

    <div class="starter-template">

        <h1>Show details of current logged user</h1>
        <p class="lead">Here below are the different $auth logged user's information</p>

        <hr>

        <span v-if="$auth.check() && $auth.ready()">
            <div>
                <h3 class="text-danger">User check :</h3>
                <div v-if="$auth.ready()">Auth ready</div>
                <div v-if="$auth.ready()">User: {{ $auth.check }}</div>

                <div v-if="$auth.check(3)">
                    <span>Auth check(3): OK</span>
                </div>
            </div>

            <div class="mt-3">
                <h3 class="text-danger">Authentificate user's info :</h3>
                <div>Full: {{ JSON.stringify($auth.user()) }}</div>
                <div>ID: {{ $auth.user().id }}</div>
                <div>name: {{ $auth.user().name }}</div>
                <div>role: {{ $auth.user().role }}</div>
                <div>email: {{ $auth.user().email }}</div>
            </div>

            <div class="mt-3">
                <button
                    @click="fetchUser"
                    class="btn btn-lg btn-primary"
                >Fetch user"</button>
            </div>

        </span>


    </div>
</template>

<script>
//import {reactive } from 'vue';
import {useAuth  } from '@websanova/vue-auth/src/v3.js';

export default {

    name: 'Logged',

    setup() {
        const auth     = useAuth();

        function fetchUser() {
            auth.fetch()
        }

        return {
            fetchUser
        }

    }
}

</script>

<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
